/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);


        // Scroll Up Button
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        $("#jobSearchLink").fancybox({
          'width'           : '75%',
          'height'          : '65%',
          'autoScale'       : false,
          'transitionIn'    : 'elastic',
          'transitionOut'   : 'none',
          'type'            : 'iframe'
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'has_google_map': {
      init: function() {
        // JavaScript to be fired on the about us page
        var map;
        function initialize_gmap(bound) {
           var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

           var isDraggable = w > 480 ? true : false;

           var mapCenter = {lat: 45.576035, lng: -70.629859};
           var zoomMap = 7;

           if (w < 780) {
             mapCenter = bound.getCenter();
             zoomMap = 7;
           }

           var settings = {
             zoom: zoomMap,
             center: mapCenter,
             scrollwheel: false,
             draggable: isDraggable,
             mapTypeControl: false,
             mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
             navigationControl: false,
             navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
             mapTypeId: google.maps.MapTypeId.ROADMAP,
             styles: [
                {
                    "featureType": "administrative",
                    "elementType": "all",
                    "stylers": [{"saturation": "-100"}]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "all",
                    "stylers": [{"visibility": "off"}]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [{"saturation": -100},{"lightness": 65},{"visibility": "on"}]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{"saturation": -100},{"lightness": "50"},{"visibility": "simplified"}]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [{"saturation": "-100"},{"visibility": "off"}]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [{"visibility": "off"}]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [{"lightness": "30"},{"visibility": "off"}]
                },
                {
                    "featureType": "road.local",
                    "elementType": "all",
                    "stylers": [{"lightness": "40"},{"visibility": "off"}]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [{"saturation": -100},{"visibility": "off"}]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{"hue": "#ffff00"},{"lightness": -25},{"saturation": -97}]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [{"lightness": -25},{"saturation": -100}]
                }
            ]
           };

           map = new google.maps.Map(document.getElementById("map_canvas"), settings);

           var center;
           function calculateCenter() {
             center = map.getCenter();
           }
           google.maps.event.addDomListener(map, 'idle', function() {
             calculateCenter();
           });
           google.maps.event.addDomListener(window, 'resize', function() {
             map.setCenter(center);
           });
         }

         function addMarker(point_lat, point_long, address, title, directions) {

           var point = new google.maps.LatLng(point_lat, point_long);

           var contentString = '<div id="gmapContent" style="text-align:left;">'+
             '<a href="' + directions + '" target="_blank"><h4>' + title + '</h4></a>'+
             '<div id="bodyContent">'+
             '<p>' + address + '</p>'+
             '</div>'+
             '</div>';

          var infoBox = '<div class="location">' +
            '<p class="title">' + title + '</p>' +
            '<p class="address">' + address + '</p>' +
            '<a href="' + directions + '" class="directionLink" target="_blank"><i class="fa fa-map-o" aria-hidden="true"></i> Get Directions</a>' +
            '</div>';

           var companyMarker = new google.maps.Marker({
             position: point,
             map: map,
             icon: '/wp-content/uploads/images/marker_red.png',
             title: title});


               $('#infoBox .location').replaceWith(infoBox);

           google.maps.event.addListener(companyMarker, 'click', function() {
               var infowindow = new google.maps.InfoWindow();
               //  infowindow.setContent(contentString);
            //    infowindow.open(map,this);

              $('#infoBox .location').replaceWith(infoBox);

           });

         }

         var locations = [];

         $('.location-map').each(function() {
           var location = [];
           location.push(parseFloat($(this).find('.map-data').find('.point_lat').data('pointlat')));
           location.push(parseFloat($(this).find('.map-data').find('.point_long').data('pointlong')));
           location.push($(this).find('.map-data').find('.address').data('address'));
           location.push($(this).find('.map-data').find('.title').data('title'));
           location.push($(this).find('.map-data').find('.directions').data('directions'));
           locations.push(location);
         });

         document.initMap = function() {
             if($('#map_canvas').length) {
               var bound = new google.maps.LatLngBounds();
               for (i = 0; i < locations.length; i++) {
                 bound.extend( new google.maps.LatLng(locations[i][0], locations[i][1]) );
               }
               initialize_gmap(bound);
               for (i = 0; i < locations.length; i++) {
                 addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], locations[i][4]);
               }
             }
         };

         var maps_api_key = 'AIzaSyBxMBGWoEO9newpbfyThZSVWPiTnLdSQnU';
         var maps_callback = 'document.initMap';
         var maps_api_url = 'https://maps.google.com/maps/api/js';
         var maps_script_tag = document.createElement('script');
         maps_script_tag.setAttribute('type', 'text/javascript');
         maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
         maps_script_tag.setAttribute('defer', '');
         maps_script_tag.setAttribute('async', '');
         document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
